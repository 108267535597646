import React, {useState, useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {GET_USER_WATCH_SETTINGS_QUERY} from '../../graphql/queries';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import PricingPackages from '../../components/pricing/pricing-packages';
import PremiumCredits from '../../components/pricing/premium-credits';
import Switch from '../../components/switch';
import './styles.scss';

const Pricing = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(false);
  const [promo, setPromo] = useState(false);
  const [showLtdText, setShowLtdText] = useState(false);
  const [annualPromo, setAnnualPromo] = useState(false);

  // const {data: hexowatchUserInfo} = useQuery(GET_USER_WATCH_SETTINGS_QUERY, {
  //   fetchPolicy: 'no-cache',
  // });
  // const {data: flashPromo, loading: flashPromoLoading} = useQuery(GET_WATCH_FLASH_PROMO_DATA);

  // useEffect(() => {
  //   if (
  //     hexowatchUserInfo &&
  //     hexowatchUserInfo.User &&
  //     hexowatchUserInfo.User.get &&
  //     hexowatchUserInfo.User.get.promoData &&
  //     hexowatchUserInfo.User.get.promoData.hexowatch_ltd
  //     // (hexowatchUserInfo.UserWatchSettings.get.pricing_package === 'FREE' ||
  //     //   hexowatchUserInfo.UserWatchSettings.get.pricing_package_interval === 'LTD')
  //   ) {
  //     setShowLtdText(true);
  //   } else {
  //     setShowLtdText(false);
  //   }
  // }, [hexowatchUserInfo]);

  // useEffect(() => {
  //   if (getUserToken()) {
  //     refetch();
  //   }
  // }, [getUserToken()]);

  // useEffect(() => {
  //   const packageHexowatch =
  //     hexowatchUserInfo &&
  //     hexowatchUserInfo.UserWatchSettings &&
  //     hexowatchUserInfo.UserWatchSettings.get &&
  //     hexowatchUserInfo.UserWatchSettings.get.pricing_package;

  //   const flashPromoDate =
  //     flashPromo &&
  //     flashPromo.User &&
  //     flashPromo.User.getWatchFlashPromoCronDate &&
  //     flashPromo.User.getWatchFlashPromoCronDate.cron_date_left;

  //   if (
  //     hexowatchUserInfo &&
  //     !hexowatchLoading &&
  //     (packageHexowatch === 'FREE' || !packageHexowatch) &&
  //     flashPromoDate &&
  //     parseInt(flashPromoDate) > new Date().getTime()
  //   ) {
  //     setPromo(true);
  //   }
  // }, [hexowatchUserInfo, hexowatchLoading, flashPromo, flashPromoLoading]);

  return (
    <div className="content-wrapper">
      <PageHero
        title="Hexowatch Pricing Plans"
        // subtitle={showLtdText ?
        //   "Kick start your productivity after the vacations with our back-to-school promo 🚀" :
        //   "Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes."}
        // subtitle="Hexowatch is your AI sidekick to monitor any website for visual, content, source code, technology, availability or price changes."
        subtitle1={
          showLtdText ? (
            <>
              <p className="m-0">
                <strong>You’re invited to our private FLASH PROMO</strong>
              </p>
              <p className="m-0">STAY AHEAD OF YOUR COMPETITION WITH HEXOWATCH</p>
              <p className="m-0">
                Monitor any website for visual, content, source code, technology, availability, or price changes.
              </p>
            </>
          ) : annualPromo ? (
            <>
              <p className="m-0 mb-2">
                SPECIAL OFFER - Save <span style={{color: '#0ab8f7', fontWeight: 500}}>60% OFF</span> our annual plans for a
                limited time only.
              </p>
            </>
          ) : (
            ''
          )
        }
        // subtitle1={<p className='promo-pricing'>Halloween Special - Save 25% all our <span onClick={() => setAnnual(true)} className='external-link'>annual plans</span></p>}
        noPaddingBottom
        countdown={showLtdText}
        pricingPage
      />
      <Layout>
        <Section className="pricing-section">
          <div className="pricing-section mx-auto">
            <div className="d-flex pricing-section-header justify-content-center mb-5">
              <div className="d-inline-block position-relative wrap-el">
                <Switch
                  text1="Monthly"
                  text2="Annually"
                  active={!annual}
                  onChange={(val: string) => setAnnual(val === 'Annually')}
                />
                {/* {showLtdText ? null : (
                  <div className="d-flex align-items-center justify-content-center get-month position-absolute">
                    {twoMonthsFreeIcon}
                  </div>
                )} */}
              </div>
            </div>

            <PricingPackages
              products={pageContext.products}
              interval={annual ? 'annual' : 'monthly'}
              promo={promo}
              // showAnnualDiscountPlans
              setAnnual={() => {
                setAnnual(true);
                // setAnnualPromo(true);
              }}
            />

            {/* <p className="text-center mt-4" style={{fontSize: '14px', color: '#050038', fontWeight: 600}}>
              All purchases are covered by our 30 day no questions asked refund policy.
            </p> */}
          </div>
        </Section>
        {/* <PricingFooter /> */}
        <PremiumCredits />
      </Layout>
    </div>
  );
};

export default Pricing;
